#gradient {
    background: -webkit-linear-gradient(0deg, rgb(70, 80, 170),  rgb(49, 79, 111)); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(0deg, rgb(70, 80, 170),  rgb(49, 79, 111)); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(0deg, rgb(70, 80, 170),  rgb(49, 79, 111)); /* For Firefox 3.6 to 15 */
    background: linear-gradient(0deg, rgb(70, 80, 170),  rgb(49, 79, 111)); /* Standard syntax */
}

.full-width-container {
    width: 100%;
    padding: 0;
    margin: 0;
}

.full-width-container .row {
    margin: 0;
}