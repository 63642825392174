// Import npm dependencies; path will be resolved relative to node_modules in rootProject.buildDir (kotlin js plugin adds `resolve` directive to webpack config)
@import "bootstrap/scss/bootstrap.scss";
@import "background";

// do not use name `.popover`, because it's used in popover library and it breaks position
.form-popover {
  cursor: pointer;
}

// Override bootstrap's attribute, that makes buttons in modal-dialog disabled
.modal-dialog {
  pointer-events: all;
}

.unsaved-marker {
  position: absolute;
  background: lightgoldenrodyellow;
  z-index: 0;
}

.hover-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.hover-button:hover .child {
}


.logo img {
    transition: 0.4s;
}

.logo:hover img {
    transition: 0.4s;
    transform: scale(1.1);
}

.logo svg {
    opacity: 0.8;
    transition: 0.4s;
}

.logo:hover svg {
    transition: 0.4s;
    opacity: 1;
}

.collapsible {
    opacity: 0;
}

.collapsible.active {
    transition: 0.3s;
    opacity: 100;
}
